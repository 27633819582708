<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
       <div class="row justify-content-center p-0 m-0">
        <div class="col-12 col-lg-8 p-0 m-0">
          <div  class="px-3 pb-5">
          <h2 class="doc-title"> Website terms and conditions of use </h2>
            <p class="doc-pera">
              <span class="pr-4"> 1. </span>
              General 
            </p>
            <p class="doc-pera">
              
This page (together with our Disclaimer, Privacy Policy, Website Acceptable Use Policy and Cookies Policy)  tells you information about us and the legal terms and conditions (“Terms”) applicable to the services we offer (the “Services”) as listed on our website (“Our Website”). 

            </p>
            <p class="doc-pera">
              
The content on our Website is provided for general information on the Services we offer. It is not intended to amount to investment advice on which you should rely. You should obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Website. Past performance is no guarantee for future results. Investments can involve significant risks and the value of them can go up or down. 

            </p>
            <p class="doc-pera">
              Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up to date.
            </p>
            <p class="doc-pera mt-5"> <span class="pr-4">2. </span>
            Applicability of the Terms
            </p>
            <p class="doc-pera">
            Please read these Terms carefully before you use our Website. These Terms tell you who we are and stipulate the Terms applicable to the use of our Website. You should print a copy of these Terms or save them to your computer for future reference.

            </p>

            <p class="doc-pera">            We may update, revise, delete and/or modify information on our Website without notice. 
Every time you wish to use our Website, please check these Terms in order to ensure you understand the Terms that apply at that time. 
</p>
            <p class="doc-pera">
Information should only be considered current as of the time of initial publication on our Website or as otherwise stated on our Website without regard to the date on which you may access the information. These Terms are only in the English language. 
</p>
            <p class="doc-pera">
By using our Website, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these terms, you must not use our Website.
</p>
            <p class="doc-pera mt-5"> <span class="pr-4">3.</span>
             Information about us
             </p>
            <p class="doc-pera">
            Our Website is used, owned and operated by Fuse.Gold Limited ("We"). We are a private company limited by shares registered in Gibraltar under company number 121092 and have our registered office at 28 Irish Town, GX11 1AA, Gibraltar. 

            </p>

            <p class="doc-pera mt-5"> <span class="pr-4">4.</span>To contact us, please email admin@fuse.gold  </p>
            <p class="doc-pera mt-5"> <span class="pr-4">5.</span> Restricted Access </p>
            <p class="doc-pera">Our Website and the Services are not directed to any person or corporate entity who is a resident of any jurisdiction where the use of our Website would be contrary to the applicable law of that jurisdiction. We do not represent that content available on or through our Website is appropriate for use or available in other locations.</p>
            <p class="doc-pera mt-5"> <span class="pr-4">6.</span> We may suspend or withdraw our Website </p>
            <p class="doc-pera"> Our Website is made available free of charge. </p>
            <p class="doc-pera">
              We do not guarantee that our Website, or any content displayed or published on it, will always be available and/or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Website for business and/or operational reasons.

            </p>
            <p class="doc-pera">
              
You are solely responsible for ensuring that all persons who access our Website through your internet connection are aware of these Terms and all other applicable terms and conditions that apply to the Website, and that such persons comply with the same.

            </p>
            <p class="doc-pera mt-5"> <span class="pr-4">7.</span> How you may use material on our Website </p>
            <p class="doc-pera">
              
We are the owner of all intellectual property rights (including patents, utility models, rights to inventions, copyright and related rights, trade-marks and service marks, trade names, utility software, applications, domains, source code (including source code materials, database rights, goodwill and the right to sue for passing off or unfair competition, rights in designs, renewals/extensions/modifications thereof and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of the world) on or in connection with our Website and/or in the material published and/or displayed on it. All such rights are reserved.





            </p>
            <p class="doc-pera">
You may print off one copy, and may download extracts, of any page(s) from our Website for your personal use and you may draw the attention of others within your organization to content posted on our Website.

            </p>
            <p class="doc-pera">
You have no right to modify copy, adapt, reverse engineer, decompile, disassemble, adapt any hard or digital copies of any materials you have printed off and/or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.

            </p>
            <p class="doc-pera">
Our status (and that of any identified contributors) as the authors of content on our Website must always be acknowledged.

            </p>
            <p class="doc-pera">
You must not use any part of the content on our Website for commercial purposes without obtaining a licence to do so from us or our licensors.

            </p>
            <p class="doc-pera">
If you print off, copy or download any part of our Website in breach of these terms of use, your right to use our Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.

            </p>
            <p class="doc-pera mt-5"> <span class="pr-4">8.</span> Our responsibility for loss or damage suffered by you</p>
<p class="doc-pera">
            We do not exclude or limit in any way our liability to you where it would be unlawful to do so. We exclude all implied conditions, warranties, representations or other terms that may apply to our Website or any content on it.

</p>
<p class="doc-pera">
We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:

</p>

<ul>
  <li> use of, or inability to use, our Website; or </li>
  <li>use of or reliance on any content displayed on our Website.</li>
</ul>
<p class="doc-pera"> In particular, we will not be liable for: </p>
<ul>
  <li>loss of profits, business, or revenue;</li>
  <li> business interruption; </li>
  <li>loss of anticipated savings;</li>
  <li>loss of business opportunity, goodwill or reputation; or</li>
  <li>any indirect or consequential loss or damage,</li>
</ul>
<p class="doc-pera"> arising from or in connection with your use of our Website. </p>
<p class="doc-pera mt-5"> <span class="pr-4">9.</span> Rules about linking to our Website</p>
<p class="doc-pera">
You may link to the home page of our Website, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.

</p>
<p class="doc-pera">
You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.

</p>
<p class="doc-pera">
You must not establish a link to our Website in any website that is not owned by you.

</p>
<p class="doc-pera">
Our Website must not be framed on any other site, nor may you create a link to any part of our Website other than the home page.

</p>
<p class="doc-pera">
We reserve the right to withdraw linking permission without notice.
</p>
<p class="doc-pera">
The website in which you are linking must comply in all respects with the content standards set out in our Acceptable Use Policy . If you wish to link to or make any use of content on our Website other than that set out above, please contact admin@fuse.gold. 
  
</p>
<p class="doc-pera"><span class="pr-4"> 10.</span> 	Which country's laws apply to any disputes?</p>
<p class="doc-pera">
If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by Gibraltar law. You and we both agree that the courts of Gibraltar will have exclusive jurisdiction.

</p>
<p class="doc-pera">
If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by Gibraltar law. We both agree to the exclusive jurisdiction of the courts of Gibraltar.

</p>




          </div>
        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "TermsConditions",
}
</script>

<style>

</style>